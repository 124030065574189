.swal-container {
  z-index: 2000 !important;
  > div {
    background-color: #e0ffff !important;
  }
}

.swal-title {
  font-family: "Poppins";
  color: #01060e !important;
}

.swal-content {
  @extend .swal-title;
}

.swal-confirm-button {
  background: linear-gradient(45deg, #32cd32 50%, #304665 90%) !important;
  color: #01060e !important;
  font-weight: bold;
  &:focus {
    box-shadow: 0px 0px 2px 3px #32cd32;
  }
}
