@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../Fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "ArJulian";
  src: local("ArJulian"), url(../Fonts/ArJulian.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Poppins";
}

hr {
  margin: 10px 0 !important;
}

.block-ui-overlay {
  background-color: #80a8a8;
}

.leaflet-interactive {
  cursor: crosshair;
}

.sketch-picker {
  background-color: #4d4d49 !important;
  span {
    color: #ceccca !important;
  }
}
